<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div class="mb-12">
          <h1>{{ $t('EventsFuture.pageTitle') }}</h1>
          <core-filters
            :active-filters-count="activeFiltersCount"
            module="events-future"
            section="filters"
            class="mb-2"
            @update="onFilterUpdate()"
          />
          <core-filter-buttons
            @apply="onFilterUpdate()"
            @reset="onFilterReset()"
            :reset-label="$t('EventsFuture.resetLabel')"
          />
        </div>
        <core-event-list
          :items="events"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :loading="loading"
          :items-per-page-options="itemsPerPageOptions"
          :loading-text="$t('EventsFuture.loading')"
          :no-data-text="$t('EventsFuture.noData')"
          module="events-future"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('events-future/setFilterValuesFromQuery', { query: to.query })
    });
  },
  metaInfo () {
    return {
      title: this.$t('EventsFuture.pageTitle'),
    }
  },
  computed: {
    ...mapGetters('events-future', [
      'events',
      'serverItemsLength',
      'loading',
      'itemsPerPageOptions',
      'activeFiltersCount',
    ]),
    options: {
      get () {
        return this.$store.state['events-future'].options
      },
      set (options) {
        this.$store.commit('events-future/SET_EVENTS_OPTIONS', options)
      },
    },
  },
  watch: {
    options: {
      async handler () {
        await this.getEvents()
      },
      deep: true,
    },
  },
  async mounted () {
    await this.getFilterItems()
  },
  methods: {
    ...mapActions('events-future',
      [
        'getEvents',
        'getFilterItems',
      ],
    ),
    onFilterUpdate () {
      this.resetListPage()
      this.getEvents()
    },
    onFilterReset () {
      this.resetListPage()
      this.$store.commit('events-future/RESET_FILTER_VALUES')
      this.$store.commit('events-future/SET_FILTER_EXPANDED', undefined)
      this.$store.dispatch('auth/resetSettings', { module: 'events-future' }, { root: true })
      this.getEvents()
    },
    resetListPage () {
      this.$store.commit('events-future/SET_EVENTS_PAGE', 1)
    },
  },
}
</script>
